import React from "react"
import { graphql } from "gatsby"
import LoadMore from "../components/LoadMore"
import DirectoryMenu from "../components/DirectoryMenu"
import Seo from "../components/Seo"
import Heading from "../components/Heading"
import PortableTextBlock from "../components/PortableTextBlock"

export default function ListingsCategories({ data }) {
  const page = data && data.page
  const posts = data && data.posts
  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
        shareGraphic={page.seo?.shareGraphic?.asset.url}
      />
      <div className="flex flex-col justify-stretch w-full">
        <section className="wrapper order-last lg:order-first">
          <div className="py-4">
            <Heading text="Directory" />
            <DirectoryMenu cols={8} />
          </div>
        </section>
        <section className="wrapper my-4">
          <Heading text={page.title} />
          {posts.nodes && <LoadMore posts={posts.nodes} />}
          {page._rawEmptyState && (
            <div class="w-full max-w-3xl">
              <PortableTextBlock value={page._rawEmptyState} />
            </div>
          )}
        </section>
      </div>
    </>
  )
}

export const query = graphql`
  query listingsCategoriesQuery($id: String!) {
    page: sanityListingsCategories(id: { eq: $id }) {
      _id
      title
      seo {
        ...seoFields
      }
      _rawEmptyState
    }
    posts: allSanityListings(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        title
        _id
        _type
        slug {
          current
        }
        content {
          subHeading
          featureImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
